// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Next = require("../../next/Next.bs.js");
var React = require("react");
var CurrentUser = require("../CurrentUser/CurrentUser.bs.js");

var allowedRoutes = [
  "/client",
  "/client/account"
];

function use() {
  var session = CurrentUser.useCurrentSession();
  var router = Next.useRouter();
  var pathname = Next.Router.pathname(router);
  React.useEffect((function () {
          if (typeof session === "object" && session.TAG === "Client") {
            var exit = 0;
            switch (session._0.status) {
              case "ACTIVE" :
                  break;
              case "ACCESS_REVOKED" :
              case "AGENT_NOT_AUTHORIZED" :
                  exit = 1;
                  break;
              
            }
            if (exit === 1) {
              if (!allowedRoutes.some(function (p) {
                      return p === pathname;
                    })) {
                Next.Router.replace("/client", undefined, undefined, router);
              }
              
            }
            
          }
          
        }), [
        session,
        pathname
      ]);
}

exports.allowedRoutes = allowedRoutes;
exports.use = use;
/* Next Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Buttons__Jsx3 = require("../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var SystemMessages = require("../systemMessages/SystemMessages.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var UseNewVersionNoticeQuery_graphql = require("../../__generated__/UseNewVersionNoticeQuery_graphql.bs.js");
var UseNewVersionNoticeUpdatableQuery_graphql = require("../../__generated__/UseNewVersionNoticeUpdatableQuery_graphql.bs.js");

var convertVariables = UseNewVersionNoticeQuery_graphql.Internal.convertVariables;

var convertResponse = UseNewVersionNoticeQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = UseNewVersionNoticeQuery_graphql.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, UseNewVersionNoticeQuery_graphql.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, UseNewVersionNoticeQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(UseNewVersionNoticeQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(UseNewVersionNoticeQuery_graphql.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(UseNewVersionNoticeQuery_graphql.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(UseNewVersionNoticeQuery_graphql.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var UpdatableQuery = {
  Types: undefined,
  readUpdatableQuery: UseNewVersionNoticeUpdatableQuery_graphql.readUpdatableQuery
};

function useNewVersionNotice() {
  var match = use(undefined, "store-only", undefined, undefined);
  var hasNotifiedAboutNewFrontendCodeVersion = match.hasNotifiedAboutNewFrontendCodeVersion;
  var codeVersionOfFrontendBundle = match.codeVersionOfFrontendBundle;
  var environment = RescriptRelay.useEnvironmentFromContext();
  var match$1 = SystemMessages.useSystemMessages();
  var addMessage = match$1.addMessage;
  React.useEffect((function () {
          var match = process.env.NEXT_PUBLIC_BUILD_ID;
          if (match !== undefined && codeVersionOfFrontendBundle === undefined) {
            RelayRuntime.commitLocalUpdate(environment, (function (store) {
                    var updatableQuery = UseNewVersionNoticeUpdatableQuery_graphql.readUpdatableQuery(store, undefined);
                    updatableQuery.updatableData.codeVersionOfFrontendBundle = match;
                    updatableQuery.updatableData.hasNotifiedAboutNewFrontendCodeVersion = false;
                  }));
          }
          
        }), [codeVersionOfFrontendBundle]);
  React.useEffect((function () {
          if (hasNotifiedAboutNewFrontendCodeVersion !== undefined && hasNotifiedAboutNewFrontendCodeVersion) {
            return ;
          }
          if (codeVersionOfFrontendBundle === undefined) {
            return ;
          }
          var intervalId = setInterval((function () {
                  var run = async function () {
                    var res = await fetch("/api/build-id");
                    var dict = await res.json();
                    if (!Array.isArray(dict) && (dict === null || typeof dict !== "object") && typeof dict !== "number" && typeof dict !== "string" && typeof dict !== "boolean") {
                      return ;
                    }
                    if (!(typeof dict === "object" && !Array.isArray(dict))) {
                      return ;
                    }
                    var match = dict["buildId"];
                    if (match !== undefined && !(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean" || !(typeof match === "string" && match !== codeVersionOfFrontendBundle))) {
                      RelayRuntime.commitLocalUpdate(environment, (function (store) {
                              var updatableQuery = UseNewVersionNoticeUpdatableQuery_graphql.readUpdatableQuery(store, undefined);
                              updatableQuery.updatableData.codeVersionOfFrontendBundle = codeVersionOfFrontendBundle;
                              updatableQuery.updatableData.hasNotifiedAboutNewFrontendCodeVersion = true;
                            }));
                      return addMessage(SystemMessages.Message.make("updated-code-notice", "info", "A new version of TAN is available. Please refresh this page to upgrade", 0, (function (param) {
                                        return JsxRuntime.jsx(Buttons__Jsx3.OutlineButton.make, {
                                                    onClick: (function (param) {
                                                        window.location.reload();
                                                      }),
                                                    children: "Refresh"
                                                  });
                                      }), false));
                    }
                    
                  };
                  run();
                }), 300000);
          return (function () {
                    clearInterval(intervalId);
                  });
        }), [
        codeVersionOfFrontendBundle,
        hasNotifiedAboutNewFrontendCodeVersion
      ]);
}

exports.Query = Query;
exports.UpdatableQuery = UpdatableQuery;
exports.useNewVersionNotice = useNewVersionNotice;
/* use Not a pure module */

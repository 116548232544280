// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function evalInt(x) {
  if (typeof x === "object") {
    return x.VAL;
  } else if (x === "large") {
    return 90;
  } else if (x === "medium") {
    return 70;
  } else if (x === "small") {
    return 50;
  } else {
    return 40;
  }
}

var Size = {
  evalInt: evalInt
};

function style(sz) {
  return Css.style({
              hd: Css.flexShrink(0),
              tl: {
                hd: Css.display("block"),
                tl: {
                  hd: Css.borderRadius({
                        NAME: "percent",
                        VAL: 50.0
                      }),
                  tl: {
                    hd: Css.objectFit("cover"),
                    tl: {
                      hd: Css.width(sz),
                      tl: {
                        hd: Css.height(sz),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            });
}

function Avatar__Jsx3$Avatar(props) {
  var __size = props.size;
  var name = props.name;
  var size = __size !== undefined ? __size : "small";
  var sizePx = evalInt(size);
  var style$1 = style({
        NAME: "px",
        VAL: sizePx
      });
  return JsxRuntime.jsx("img", {
              className: style$1,
              title: name,
              alt: name,
              src: props.src,
              onClick: props.onClick
            });
}

var Avatar = {
  style: style,
  make: Avatar__Jsx3$Avatar
};

var make = Avatar__Jsx3$Avatar;

exports.Size = Size;
exports.Avatar = Avatar;
exports.make = make;
/* Css Not a pure module */

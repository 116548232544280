// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var AvatarSrcUrl = require("../../utils/AvatarSrcUrl.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

var Avatar = {};

function name(v) {
  return v.firstName + (" " + v.lastName);
}

function label(v) {
  return Core__Option.getOr(v.displayName, v.firstName + (" " + v.lastName));
}

function avatarURL(width, height, v) {
  return AvatarSrcUrl.from_key(width, height, Core__Option.map(v.avatar, (function (a) {
                    return a.key;
                  })));
}

exports.Avatar = Avatar;
exports.name = name;
exports.label = label;
exports.avatarURL = avatarURL;
/* AvatarSrcUrl Not a pure module */
